import {Fragment, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {MenuIcon, XIcon} from '@heroicons/react/outline'
import StoreNav from "../store-nav";
import Messages from "../messages";
import {useLazyGetCustomerUserQuery} from "../../store/apiSlice";
import {useAppDispatch} from "../../store/store";
import {Customer} from "../../models/models";
import {setCustomer} from "../../store/user-slice";
import {useStoreUser} from "../../hooks/store-user";
import Link from "next/link";
import Image from "next/image";

const navigation = {
    pages: [
        { name: 'Events', href: '/' },
        { name: 'Clubs', href: '/clubs' },
        { name: 'Cities', href: '/cities' },
        { name: 'About', href: '/store/about' },
        { name: 'Support', href: '/store/support' },
        { name: 'Industry Sign Up', href: '/signup' }
    ],
}

export default function StoreLayout({children}: any) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const {loggedIn} = useStoreUser()
    const [getCustomerUser] = useLazyGetCustomerUserQuery()
    const dispatch = useAppDispatch()
    useEffect(() => {
        const load = async () => {
            const result: Customer = (await getCustomerUser()).data as Customer
            if (result) {
                await dispatch(setCustomer(result))
            }
        }
        load()
    }, [getCustomerUser, dispatch])

    return (
        <div className="bg-white">
            {/* Mobile menu */}
            <Transition.Root show={mobileMenuOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setMobileMenuOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
                            <div className="px-4 pt-5 pb-2 flex">
                                <button
                                    type="button"
                                    className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                                    onClick={() => setMobileMenuOpen(false)}
                                >
                                    <span className="sr-only">Close menu</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>

                            <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                                {navigation.pages.map((page) => (
                                    <div key={page.name} className="flow-root">
                                        <Link onClick={() => setMobileMenuOpen(false)} href={page.href} className="-m-2 p-2 block font-medium text-gray-900">
                                            {page.name}
                                        </Link>
                                    </div>
                                ))}
                            </div>

                            {!loggedIn && <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                                <div className="flow-root">
                                    <Link href={`${process.env.NEXT_PUBLIC_MEDUSA_BASE_URL}/store/auth/auth0`} className="-m-2 p-2 block font-medium text-gray-900">
                                        Create an account
                                    </Link>
                                </div>
                                <div className="flow-root">
                                    <Link href={`${process.env.NEXT_PUBLIC_MEDUSA_BASE_URL}/store/auth/auth0`} className="-m-2 p-2 block font-medium text-gray-900">
                                        Sign in
                                    </Link>
                                </div>
                            </div>}
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition.Root>

            <header className="relative z-10">
                <nav aria-label="Top">
                    {/* Top navigation */}

                    {/* Secondary navigation */}
                    <div className="bg-white">
                        <div className="border-b border-gray-200">
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="h-16 md:h-24 flex items-center justify-between">
                                    {/* Logo (lg+) */}
                                    <div className="hidden lg:flex lg:items-center">
                                        <Link href="/">
                                            <span className="sr-only">My Comedy Tickets</span>
                                            <Image
                                                className="h-14 w-auto"
                                                src="https://i.imgur.com/VuRvaoH.png"
                                                alt=""
                                                width={450}
                                                height={100}
                                            />
                                        </Link>
                                    </div>

                                    <div className="hidden h-full lg:flex">
                                                {navigation.pages.map((page) => (
                                                    <Link
                                                        key={page.name}
                                                        href={page.href}
                                                        className="flex items-center text-base font-medium text-gray-700 hover:text-gray-800 mr-1 ml-5"
                                                    >
                                                        {page.name}
                                                    </Link>
                                                ))}
                                    </div>

                                    {/* Mobile menu and search (lg-) */}
                                    <div className="flex-shrink flex items-center lg:hidden">
                                        <button
                                            type="button"
                                            className="-ml-2 bg-white p-2 rounded-md text-gray-400"
                                            onClick={() => setMobileMenuOpen(true)}
                                        >
                                            <span className="sr-only">Open menu</span>
                                            <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>

                                    {/* Logo (lg-) */}
                                    <Link href="/" className="lg:hidden">
                                        <span className="sr-only">MyComedyTickets.com</span>
                                        <Image
                                            className="h-10 w-auto"
                                            src="https://i.imgur.com/VuRvaoH.png"
                                            alt=""
                                            width={450}
                                            height={100}
                                        />
                                    </Link>

                                    <div className="flex-1 flex items-center justify-end">
                                        <div className="flex items-center lg:ml-8">
                                            <div className="flow-root">
                                                <StoreNav/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>

            <main>
                <Messages/>

                {children}

            </main>

            <footer aria-labelledby="footer-heading" className="bg-white">
                <h2 id="footer-heading" className="sr-only">
                    Footer
                </h2>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="border-t border-gray-200">
                        <div className="pt-16 pb-20">
                            <div className="md:flex md:justify-center">
                                <img
                                    src="https://i.imgur.com/VuRvaoH.png"
                                    alt=""
                                    className="h-8 w-auto"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="py-10 md:flex md:items-center md:justify-between">
                        <div className="flex gap-4">
                            <p className="text-sm text-gray-500">&copy; 2023 All Rights Reserved</p>
                            <a className={"text-sm text-gray-500"} href={"/terms-of-use.html"}>Terms of Use</a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
