import {
    Customer as MedusaCustomer,
    Order,
    Product as MedusaProduct,
    ProductVariant as MedusaProductVariant,
    User as MedusaUser
} from "@medusajs/medusa";
import {PaginationInput} from "../hooks/pagination";


export interface SeatingGroup {
    capacity: number
}
export interface VenueSection {
    seatingGroups: SeatingGroup[]
}

export interface VenueArrangement {
    id: string
    name: string
    venue: Venue
    sections: VenueSection[]
}

export interface Venue {
    id: string
    name: string
    address: string
    city: City
    province: string
    postalCode: string
    country: string
    phone: string
    slug: string
    timezone: string
    arrangements: VenueArrangement[]
    instagramProfile: string
    twitterProfile: string
    tiktokProfile: string
    picture: string
    notificationSubscriptions: VenueNotificationSubscription[],
    ticketStrategy: string

}

export interface Currency {
    code: string
}

export interface Ticket {
    id: string;
    productVariant: ProductVariant;
    order: Order
    status: string;
}

export type PaginationContext = PaginationInput & {
    count: number
}

export type PaginatedList<T> = PaginationContext & {
    items: T[]
}

export type AuthError = {
    code?: string
    message: string
    type: string
}

export type User = MedusaUser &  {
    tips: Tip[]
    accounts: Account[]
    paymentDestination: PaymentDestination
    participant: Participant
}

export type Product = MedusaProduct & {
    serviceFeeValue: number
    account: Account
    venueArrangement: VenueArrangement
    activeVariants: ProductVariant[]
    inactiveVariants: ProductVariant[]
    categories: ProductCategory[]
}
export type VariantParticipant = {
    variant: ProductVariant
    participant: Participant
    role: string
}
export type ProductVariant = MedusaProductVariant & {
    variantParticipants: VariantParticipant[]
}

export type Participant = {
    id: string
    name: string
    picture: string
    instagramProfile: string
    twitterProfile: string
    tiktokProfile: string
    profile: string
    slug: string
}

export type ParticipantUser = Participant & {
    user: User
}

export const INITIATED_AUTH_FROM_PATH = "initiatedAuthFromPath";

export function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export function isPaymentAccount(obj: any): obj is PaymentAccount {
    return obj !== undefined && (obj as PaymentAccount).default_currency !== undefined;
}
export interface PaymentAccount {
    id: string
    charges_enabled: boolean
    default_currency: string
    details_submitted: boolean
}

export interface AccountLink {
    url: string
    created: number
    expires_at: number
}

export type ProductPaymentInfo = {
    transferAmount: number
    salesAmount: number
}

export function isProductPaymentInfo(obj: any): obj is ProductPaymentInfo {
    return obj !== undefined &&
        (obj as ProductPaymentInfo).salesAmount !== undefined &&
        (obj as ProductPaymentInfo).transferAmount !== undefined;
}

export type Tip = {
    amount: number
    currency: string
    name: string
    note: string
    unitCount: number
    unitCost: number
}

export type Province = {
    name: string
    abbreviation: string
}

export type Country = {
    name: string
    abbreviation: string
}

export type Follow = {
    venue: Venue
}

export type Customer = MedusaCustomer & {
    venueFollows: Follow[]
}

export type NotificationType = {
    id: string
    description: string
}

export type VenueNotificationSubscription = {
    id: string
    notificationType: NotificationType
    venue: Venue
}

export type PaymentDestination = {
    id: string
    name: string
    identifier: string
    currency: string
    paymentAccountId: string
    paymentAccountMetadata: Record<string, unknown>
    paymentAccount: PaymentAccount
    enabled: boolean
}

export type Account = {
    id: string
    name: string
    storeUrl: string
    cmsApiKey: string
    cmsSecretKey: string
    productCategories: ProductCategory[]
    users: User[]
    paymentDestinations: PaymentDestination[]
}

export enum FormModes {
    CREATE,
    UPDATE
}

export type ProductCategory = {
    id: string
    name: string
    handle: string
}

export type TicketGroup = {
    id: string
    tickets: Ticket[]
}

export type City = {
    id: string
    name: string
    slug: string
    thumbnail: string
}