import {useSelector} from "react-redux";
import {selectErrors, selectSuccess} from "../store/messages-slice";

export default function Messages() {
    const errors = useSelector(selectErrors);
    const success = useSelector(selectSuccess)
    return <>
        {errors && errors.map( (m, i) => <div className="mt-4 mb-1 alert alert-error shadow-lg" key={i}>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span>{m}</span>
            </div>
        </div>)}
        {success && success.map( (m, i) => <div className="mt-4 mb-1 alert alert-success shadow-lg" key={i}>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span>{m}</span>
            </div>
        </div>)}
    </>

}

