import Link from "next/link";
import React from "react";
import {useLogoutCustomerMutation} from "../store/apiSlice";
import {Menu} from "@headlessui/react";
import {classNames} from "../models/models";

export default function LogoutButton() {
    const [logoutCustomer] = useLogoutCustomerMutation()
    const logout = (e: any) => {
        e.preventDefault()
        logoutCustomer().then(() => {
            window.location.href = '/'
        })
    }

    return <Menu.Item>{({ active }) => (
        <Link
            href="#"
            onClick={logout}
            className={classNames(
                active ? 'bg-gray-100' : '',
                'block px-4 py-2 text-base text-gray-700'
            )}
        >
            Logout
        </Link>
    )}</Menu.Item>
}