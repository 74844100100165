import React, {Fragment} from 'react';
import {useStoreUser} from "../hooks/store-user";
import LogoutButton from "./logout-button";
import LoginButton from "./login-button";
import CartButton from "./cart-button";
import {Menu, Transition} from "@headlessui/react";
import MyOrdersButton from "./my-orders-button";
import SettingsButton from "./settings-button";
import StoreUserMenu from "./store-menu-icon";
import ProfileButton from "./profile-button";

export default function StoreNav() {
    const {loggedIn} = useStoreUser()
    return <div className="flex group relative dropdown">
        <CartButton/>
        {!loggedIn && <LoginButton/>}
        {/* Profile dropdown */}
        {loggedIn && <Menu as="div" className="ml-3">
            <div>
                <StoreUserMenu />
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white">
                    <MyOrdersButton/>
                    <ProfileButton/>
                    <SettingsButton/>
                    <LogoutButton/>
                </Menu.Items>
            </Transition>
        </Menu>}
    </div>
}