import {Menu} from "@headlessui/react";
import {useStoreUser} from "../hooks/store-user";

export default function StoreUserMenu() {
    const {picture} = useStoreUser()
    return <Menu.Button
        className="max-w-xs bg-white flex items-center text-sm rounded-full">
        <span className="sr-only">Open user menu</span>

        <img
            className="h-10 w-10 rounded-full"
            src={picture}
            alt=""
        />
    </Menu.Button>
}